import _actions from "./actions";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actionCreators = exports.stringifyJsonOrBytes = void 0;
const actions_1 = _actions;
/**
 * Creates a full access key with full access permissions.
 * @returns A new full access key.
 */
function fullAccessKey() {
  return new actions_1.AccessKey({
    nonce: 0,
    permission: new actions_1.AccessKeyPermission({
      fullAccess: new actions_1.FullAccessPermission({})
    })
  });
}
/**
 * Creates an access key with function call permission for a specific receiver and method names.
 * @param receiverId The NEAR account ID of the function call receiver.
 * @param methodNames An array of method names allowed for function calls.
 * @param allowance An optional allowance (maximum amount) for the function call. Default: Unlimited.
 * @returns A new access key with function call permission.
 */
function functionCallAccessKey(receiverId, methodNames, allowance) {
  return new actions_1.AccessKey({
    nonce: 0,
    permission: new actions_1.AccessKeyPermission({
      functionCall: new actions_1.FunctionCallPermission({
        receiverId,
        allowance,
        methodNames
      })
    })
  });
}
/**
 * Creates a new action for creating a new NEAR account.
 * @returns A new action for creating a new account.
 */
function createAccount() {
  return new actions_1.Action({
    createAccount: new actions_1.CreateAccount({})
  });
}
/**
 * Creates a new action for deploying a contract with the provided code.
 * @param code The Uint8Array representing the code of the contract.
 * @returns A new action for deploying a contract.
 */
function deployContract(code) {
  return new actions_1.Action({
    deployContract: new actions_1.DeployContract({
      code
    })
  });
}
/**
 * Converts an input argument to a Buffer, handling cases for both JSON and Uint8Array.
 * @param args The input argument, either JSON object or Uint8Array.
 * @returns A Buffer representation of the input argument.
 */
function stringifyJsonOrBytes(args) {
  const isUint8Array = args.byteLength !== undefined && args.byteLength === args.length;
  return isUint8Array ? args : Buffer.from(JSON.stringify(args));
}
exports.stringifyJsonOrBytes = stringifyJsonOrBytes;
/**
 * Constructs {@link Action} instance representing contract method call.
 *
 * @param methodName the name of the method to call
 * @param args arguments to pass to method. Can be either plain JS object which gets serialized as JSON automatically
 *  or `Uint8Array` instance which represents bytes passed as is.
 * @param gas max amount of gas that method call can use
 * @param deposit amount of NEAR (in yoctoNEAR) to send together with the call
 * @param stringify Convert input arguments into bytes array.
 * @param jsContract  Is contract from JS SDK, skips stringification of arguments.
 */
function functionCall(methodName, args, gas = BigInt(0), deposit = BigInt(0), stringify = stringifyJsonOrBytes, jsContract = false) {
  if (jsContract) {
    return new actions_1.Action({
      functionCall: new actions_1.FunctionCall({
        methodName,
        args,
        gas,
        deposit
      })
    });
  }
  return new actions_1.Action({
    functionCall: new actions_1.FunctionCall({
      methodName,
      args: stringify(args),
      gas,
      deposit
    })
  });
}
/**
 * Creates a new action for transferring funds, optionally specifying a deposit amount.
 * @param deposit The amount to be deposited along with the transfer. Default: 0.
 * @returns A new action for transferring funds.
 */
function transfer(deposit = BigInt(0)) {
  return new actions_1.Action({
    transfer: new actions_1.Transfer({
      deposit
    })
  });
}
/**
 * Creates a new action for staking tokens, specifying the stake amount and public key.
 * @param stake The amount to be staked. Default: 0.
 * @param publicKey The public key associated with the staking action.
 * @returns A new action for staking tokens.
 */
function stake(stake = BigInt(0), publicKey) {
  return new actions_1.Action({
    stake: new actions_1.Stake({
      stake,
      publicKey
    })
  });
}
/**
 * Creates a new action for adding a public key with a specified access key.
 * @param publicKey The public key to be added.
 * @param accessKey The access key associated with the added public key.
 * @returns A new action for adding a public key.
 */
function addKey(publicKey, accessKey) {
  return new actions_1.Action({
    addKey: new actions_1.AddKey({
      publicKey,
      accessKey
    })
  });
}
/**
 * Creates a new action for deleting a public key.
 * @param publicKey The public key to be deleted.
 * @returns A new action for deleting a public key.
 */
function deleteKey(publicKey) {
  return new actions_1.Action({
    deleteKey: new actions_1.DeleteKey({
      publicKey
    })
  });
}
/**
 * Creates a new action for deleting an account with the specified beneficiary ID.
 * @param beneficiaryId The NEAR account ID of the beneficiary.
 * @returns A new action for deleting an account.
 */
function deleteAccount(beneficiaryId) {
  return new actions_1.Action({
    deleteAccount: new actions_1.DeleteAccount({
      beneficiaryId
    })
  });
}
/**
 * Creates a new action for a signed delegation, specifying the delegate action and signature.
 * @param delegateAction The delegate action to be performed.
 * @param signature The signature associated with the delegate action.
 * @returns A new action for a signed delegation.
 */
function signedDelegate({
  delegateAction,
  signature
}) {
  return new actions_1.Action({
    signedDelegate: new actions_1.SignedDelegate({
      delegateAction,
      signature
    })
  });
}
exports.actionCreators = {
  addKey,
  createAccount,
  deleteAccount,
  deleteKey,
  deployContract,
  fullAccessKey,
  functionCall,
  functionCallAccessKey,
  signedDelegate,
  stake,
  transfer
};
export default exports;